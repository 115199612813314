/*
 * @Author: diaowangtao
 * @Date: 2020-08-18 17:19:10
 * @Description: 
 */
import axios from 'axios'
import myVue from '@/main.js'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000000 // request timeout
  //timeout: 1
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // do something with request error
    myVue.$store.commit('changeError', 1)
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.status !== '0000') {
      if (res.status == '1000' || res.status == '4000') {
        //1000加入我们列表没有数据，4000网络连接异常，重复提交
        if(res.message=='网络连接异常！'){
          myVue.$store.commit('changeError', 1)
        }
        //修改成 返回 res 显示暂无数据
        return res
      } else {
        myVue.$store.commit('changeError', 1)
      }
      console.log(res.message || 'Error')
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      if (!res.data) {
        return res.message
      } else {
        console.log(res.message)
        return res.data
      }

    }
  },
  error => {
    myVue.$store.commit('changeError', 1)
    console.log('err' + error) // for debug
    console.log({
      message: error.message,
      duration: 1.5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
